.ui-draggable-dragging.grid-stack-item-inner {
	opacity: 0.8;
	background: var(--#{$prefix}bg-surface-secondary);
	display: flex;
	align-items: center;
	height: 100%;
	padding: 0.85rem;

	.grid-stack-item-size,
	.delete-grid-item {
		display: none;
	}
}
.grid-stack .grid-stack-placeholder > .placeholder-content {
	background-color: var(--#{$prefix}bg-surface-secondary) !important;
}

.unused-grid-elements {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;

	.grid-stack-item {
		margin: 0 0.35rem 0.65rem 0;
		user-select: none;

		&:hover {
			cursor: move;
			background: var(--#{$prefix}bg-surface-secondary);
		}
	}

	.grid-stack-item-content {
		background: var(--#{$prefix}bg-surface);
		border: 1px solid var(--#{$prefix}border-color);

		&:hover {
			cursor: move;
			background: var(--#{$prefix}bg-surface-secondary);
		}

		> div {
			padding: 0.85rem 1.85rem !important;
		}
	}

	.grid-stack-item-size,
	.grid-stack-item-title {
		pointer-events: none;
	}

	.grid-stack-item-size {
		display: none;
	}

	.delete-grid-item {
		display: none;
	}
}

//.book-layout-unused-elements-- {
//	.grid-stack-item {
//		margin: 0 0.35rem 0.65rem 0;
//		border: 1px solid $border-color;
//		border-left: 3px solid $blue;
//
//		&:hover {
//			cursor: move;
//			background: $gray-50;
//		}
//	}
//
//	.grid-stack-item-content {
//		display: flex;
//		justify-content: space-between;
//		align-items: center;
//		padding: 8px 15px;
//	}
//}

.grid-stack {
	.grid-stack-item-content {
		background: var(--#{$prefix}bg-surface-secondary);
		border: 1px solid var(--#{$prefix}border-color);

		&:hover {
			cursor: move;
			background: var(--#{$prefix}bg-surface-tertiary);
		}
	}

	.grid-stack-item-inner {
		position: relative;
		display: flex;
		align-items: center;
		height: 100%;
		padding: 0.85rem;
	}

	.grid-stack-item-size {
		position: absolute;
		top: 4px;
		right: 30px;
		color: #868fa5;
		font-size: .7rem;
		pointer-events: none;
		user-select: none;
	}

	.delete-grid-item {
		display: inline-flex;
		position: absolute;
		top: 0;
		right: 0;
		color: #868fa5;

		&:hover {
			color: #00a8ff;
		}
	}
}

$svg-markup-color: e1e8f0;
$svg-markup: 'M110 0h.1v120h-.1zM100 0h.1v120h-.1zM90 0h.1v120H90zM80 0h.1v120H80zM70 0h.1v120H70zM60 0h.1v120H60zM50 0h.1v120H50zM40 0h.1v120H40zM30 0h.1v120H30zM20 0h.1v120H20zM10 0h.1v120H10z';
.grid-markup {
	height: 1400px;
	border: 1px solid var(--#{$prefix}border-color);
	border-radius: .25rem;

	&:before {
		content: "";
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 120 120'%3E%3Cg fill='%23#{$svg-markup-color}'%3E%3Cpath d='#{$svg-markup}'/%3E%3C/g%3E%3C/svg%3E");
		background-size: 100%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		//opacity: 0.35;
		padding: var(--#{$prefix}card-spacer-y) var(--#{$prefix}card-spacer-x);
	}
}

@include color-mode(dark) {
	$svg-markup-color: 253149;
	.grid-markup {
		&:before {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 120 120'%3E%3Cg fill='%23#{$svg-markup-color}'%3E%3Cpath d='#{$svg-markup}'/%3E%3C/g%3E%3C/svg%3E");
		}
	}
}