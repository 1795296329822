//.dropzone--- {
//  border: 1px dashed var(--#{$prefix}border-color);
//  color: var(--#{$prefix}muted);
//  padding: 1rem;
//
//  &.dz-drag-hover {
//    border: 1px dashed var(--#{$prefix}primary);
//    background: rgba(var(--#{$prefix}primary-rgb), .01);
//    color: var(--#{$prefix}primary);
//
//    .dz-message {
//      opacity: 1;
//    }
//  }
//
//  .dz-preview {
//    margin: .5rem;
//
//    .dz-image {
//      border-radius: $border-radius;
//    }
//
//    .dz-success-mark {
//      height: 54px;
//    }
//  }
//}


.drop-zone {
  font-size: 1.05rem; /* 20 */
  background-color: transparent;
  position: relative;
  padding: 100px 20px;
  text-align: center;

  outline: 2px dashed $border-color;
  outline-offset: 0;
  transition: outline-offset .15s ease-in-out, background-color .15s linear;

  .drop-zone-dragndrop {
    display: inline;
  }

  //.drop-zone-icon {
  //  width: 100%;
  //  height: 80px;
  //  fill: #92b0b3;
  //  display: block;
  //  margin-bottom: 40px;
  //}


  &.is-dragover {
    outline-offset: -10px;
    outline-color: darken($border-color, 20%);
    //background-color: #fff;
  }

  &.is-uploading .drop-zone-container,
  &.is-success .drop-zone-container,
  &.is-error .drop-zone-container {
    visibility: hidden;
  }

  &.is-uploading .drop-zone-uploading,
  &.is-success .drop-zone-success,
  &.is-error .drop-zone-error {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;

    transform: translateY(-50%);
  }

  .drop-zone-uploading,
  .drop-zone-success,
  .drop-zone-error {
    display: none;
  }

  .drop-zone-uploading {
    font-style: italic;
  }

  .drop-zone-success {
    animation: appear-from-inside .25s ease-in-out;
  }

  @keyframes appear-from-inside {
    from {
      transform: translateY(-50%) scale(0);
    }
    75% {
      transform: translateY(-50%) scale(1.1);
    }
    to {
      transform: translateY(-50%) scale(1);
    }
  }

  //.drop-zone-restart {
  //  font-weight: 700;
  //}

  //.drop-zone-restart:focus,
  //.drop-zone-restart:hover {
  //  color: $primary;
  //}

  .drop-zone-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .drop-zone-input + label {
    max-width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
  }

  .drop-zone-input + label:hover strong,
  .drop-zone-input:focus + label strong,
  .drop-zone-input.has-focus + label strong {
    color: $primary;
  }

  .drop-zone-input:focus + label,
  .drop-zone-input.has-focus + label {
    outline: 1px dotted #000;
  }

  .drop-zone-input + label * {
    /* pointer-events: none; */
    /* in case of FastClick lib use */
  }

  .drop-zone-button {
    //font-weight: 700;
    //color: #e5edf1;
    //background-color: $primary;
    display: none;
    //padding: 8px 16px;
    //margin: 40px auto 0;
  }

  .drop-zone-button:hover,
  .drop-zone-button:focus {
    background-color: #0f3c4b;
  }
}

.no-js .drop-zone-input + label {
  display: none;
}

.no-js .drop-zone-button {
  display: block;
}