$select-color-dropdown: #fff;
$select-color-dropdown-border-top: red;
$select-max-height-dropdown: 400px;
$input-border-width: 1px;

@import "~tom-select/src/scss/tom-select.bootstrap5.scss";

:root {
  --ts-pr-clear-button: 0rem;
  --ts-pr-caret: 0rem;
}

.ts-input {
  color: inherit;
}

.ts-control {
  color: inherit;

  .dropdown-menu {
    width: 100%;
    height: auto;
  }
}
.#{$select-ns}-control {
  .item{
    white-space: nowrap;
    overflow: hidden;
  }
}


.ts-wrapper {
  .form-control, 
  .form-select,
  &.form-control, 
  &.form-select {
    box-shadow: $input-box-shadow; 
  }

  &.is-invalid,
  &.is-valid {
    .ts-control {
      --ts-pr-clear-button: 1.5rem;
    }
  }
}

.ts-dropdown {
  background: var(--#{$prefix}bg-surface);
  color: var(--#{$prefix}body-color);
  box-shadow: var(--#{$prefix}box-shadow-dropdown);

  .option {
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  }
}

.ts-control,
.ts-control input {
  color: var(--#{$prefix}body-color);
}

.ts-control input {
  &::placeholder{
    color: $input-placeholder-color;
  }
}

.ts-wrapper.multi .ts-control > div {
  background: var(--#{$prefix}bg-surface-secondary);
  border: 1px solid var(--#{$prefix}border-color);
  color: var(--#{$prefix}body-color);
}
